import Head from 'next/head';
import NextLink from 'next/link';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Button, Container, Grid } from '@mui/material';
import { Google as GoogleIcon } from '@icons/google';
import { getCookie } from "cookies-next";
import { signIn } from 'next-auth/react';



export default function Login({}) {
  const router = useRouter();
  
  const handleLogin = async () => {
    await signIn('google', {
      callbackUrl: `${process.env.NEXT_PUBLIC_ADMIN_API_HOST}`,
    });  
  };
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%'
        }}
      >
        <Container maxWidth="100%" minHeight="100%">
          <Grid
            item
            mb={2}
          >
            <img
              src='/static/images/play.png'
              alt='logo'
              loading="lazy"
              width={'100%'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            textAlign={'center'}
          >
            <img
              src='/static/images/btn_google_signin.png'
              alt='google'
              height={'20%'}
              width={'20%'}
              onClick={handleLogin}
              style={{cursor:"pointer"}}
            />
          </Grid>
        </Container>
      </Box>
    </>
  );
};
